import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProject } from "../../actions/projects";
import { useMsal } from "@azure/msal-react";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";

const AddProject = () => {
  const dispatch = useDispatch();
  const msalContext = useMsal();
  const navigate = useNavigate();

  const saveProject = (data) => {
    console.log("saveProject: " + data);
    const { name, company } = data;
    dispatch(createProject(msalContext, name, company))
      .then((response) => {
        navigate(`/admin/edit/${response.id}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Form
        onSubmit={saveProject}
        render={(formRenderProps) => (
          <FormElement
            style={{
              maxWidth: 650,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Label>Project Name</Label>
                <Field name={"name"} component={Input} />
              </div>

              <div className="mb-3">
                <Label>Company</Label>
                <Field
                  name="company"
                  component={DropDownList}
                  data={["ACC", "LCA"]}
                />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                disabled={!formRenderProps.allowSubmit}
              >
                Create
              </button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default AddProject;
