import { RETRIEVE_USERPROFILE } from "./types";
import { silentRequest } from "../authConfig";

import UserProfileService from "../services/UserProfileService";

export const retrieveUserProfile = (msalContext) => async (dispatch) => {
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;
  const request = {
    ...silentRequest,
    account: accounts[0],
  };

  const tokenResponse = await instance.acquireTokenSilent(request);
  try {
    const token = tokenResponse.accessToken;
    const userProfileResponse = await UserProfileService.get(token);
    dispatch({
      type: RETRIEVE_USERPROFILE,
      payload: userProfileResponse.data,
    });
    return Promise.resolve(userProfileResponse.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
