import React from "react";
import { Form, Field } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

const Test = () => {
  // ...
  const handleSubmit = (data) => alert(JSON.stringify(data, null, 2));
  const rooms = ["Room A", "Room B"];
  return (
    <Form
      onSubmit={handleSubmit}
      render={({ allowSubmit, onSubmit }) => (
        <form onSubmit={onSubmit} className="k-form">
          <fieldset>
            <legend>Make a reservation</legend>
            <div>
              <Field name="firstName" label="First name" component={Input} />
            </div>
            <div>
              <Field name="lastName" label="Last name" component={Input} />
            </div>
            <div>
              <Field
                name="room"
                label="Room"
                component={DropDownList}
                data={rooms}
              />
            </div>
          </fieldset>
          <Button type="submit" primary={true} disabled={!allowSubmit}>
            Submit
          </Button>
        </form>
      )}
    />
  );
};

export default Test;
