import http from "../http-common";
import { silentRequest } from "../authConfig";

const get = (token) => {
  return http.get(`/userprofile`, getConfig(token));
};

const getConfig = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return config;
};

const UserProfileService = {
  get,
};

export default UserProfileService;
