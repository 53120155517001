import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import { msalConfig } from "./authConfig";
import store from "./store";

import JobListing from "./components/JobListing";
import { FileUpload } from "./components/FileUpload";
import Admin from "./components/admin";
import AdminList from "./components/admin/list";
import AdminCreate from "./components/admin/create";
import AdminEdit from "./components/admin/edit";
import AdminTest from "./components/admin/test";

let config = msalConfig;
config.auth.redirectUri = window.location.origin;

const msalInstance = new PublicClientApplication(config);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<JobListing />} />
              <Route path="joblisting" element={<JobListing />} />
              <Route path="upload" element={<FileUpload />} />
              <Route path="admin" element={<Admin />}>
                <Route index element={<AdminList />} />
                <Route path="create" element={<AdminCreate />} />
                <Route path="edit/:id" element={<AdminEdit />} />
                <Route path="test" element={<AdminTest />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
