import { RETRIEVE_USERPROFILE } from "../actions/types";

const initialState = {};

const userProfileReducer = (userProfile = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_USERPROFILE:
      return payload;

    default:
      return userProfile;
  }
};

export default userProfileReducer;
