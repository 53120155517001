import axios from "axios";

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-type": "application/json",
  },
});


//instance.interceptors.request.use(function () {/*...*/});

export default instance;