import React from "react";
import {  useSelector } from "react-redux";

const JobListingTabBdc = () => {
  const projectsSorted = useSelector((state) =>
    state.projects.sort(function (a, b) {
      return a.jobNumber.localeCompare(b.jobNumber);
    })
  );

  const projectsFiltered = projectsSorted.filter(
    (p) => p.jobNumberDesignBuild !== ""
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h4>Under Construction</h4>
          <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Job No</th>
                <th>Job Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projectsFiltered &&
                projectsFiltered.map((project, index) => (
                  <tr key={project.id}>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.jobNumberDesignBuild}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.name}
                    </td>
                    <td 
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}>
                    {(project.sharepoint.documentsCollectionUri !== "")? <a href={project.sharepoint.documentsCollectionUri} target="_blank">Files</a> : <div></div>}
                    </td>
                    <td 
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}>
                    {(project.procore.projectUri !== "")? <a href={project.procore.projectUri} target="_blank">Procore</a> : <div></div>}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};

export default JobListingTabBdc;
