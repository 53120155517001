import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveProject,
  updateProject,
  deleteProject,
} from "../../actions/projects";
import { useMsal } from "@azure/msal-react";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";

const Edit = (props) => {
  const initialProjectState = {
    id: null,
    name: "",
    company: "",
    jobNumber: "",
  };
  const [currentProject, setCurrentProject] = useState(initialProjectState);
  const [message, setMessage] = useState("");

  const userProfile = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const msalContext = useMsal();

  const getProject = (projectId) => {
    dispatch(retrieveProject(msalContext, projectId))
      .then((response) => {
        setCurrentProject(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getProject(id);
  }, [id]);

  const saveChanges = (data) => {
    dispatch(updateProject(msalContext, data.id, data))
      .then((response) => {
        setCurrentProject(response);
        navigate("/admin");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClickDelete = (data) => {
    dispatch(deleteProject(msalContext, currentProject.id))
      .then((response) => {
        navigate("/admin");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {currentProject.id === null ? (
        <p>Loading...</p>
      ) : (
        <Form
          onSubmit={saveChanges}
          initialValues={currentProject}
          render={(formRenderProps) => (
            <FormElement
              style={{
                maxWidth: 650,
              }}
            >
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  <Label>Project Name</Label>
                  <Field name={"name"} component={Input} />
                </div>

                <div className="mb-3">
                  <Label>Company</Label>
                  <Field
                    name="company"
                    component={DropDownList}
                    data={["ACC", "LCA"]}
                  />
                </div>

                <div className="mb-3">
                  <Label>Stage</Label>
                  <Field
                    name="stage"
                    component={DropDownList}
                    data={[
                      "Estimating",
                      "Precon",
                      "Inactive",
                      "Underway",
                      "Completed",
                      "Archived",
                    ]}
                  />
                </div>

                <div className="mb-3">
                  <Label>Job Number</Label>
                  <Field name={"jobNumber"} component={Input} />
                </div>
                <div className="mb-3">
                  <Label>Precon Job Number</Label>
                  <Field name={"jobNumberPrecon"} component={Input} />
                </div>
                <div className="mb-3">
                  <Label>Design/Build Job Number</Label>
                  <Field name={"jobNumberDesignBuild"} component={Input} />
                </div>
                <div className="mb-3">
                  <Label>Estimating Cost Code</Label>
                  <Field name={"costCodeEstimating"} component={Input} />
                </div>
              </fieldset>
              <div className="k-form-buttons">
                <Button
                  title="Export"
                  onClick={() => {
                    navigate("/admin");
                  }}
                >
                  Back
                </Button>
                <button
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  disabled={!formRenderProps.allowSubmit}
                >
                  Save
                </button>
                {userProfile.isSuperAdmin ||
                (userProfile.isAdmin &&
                  currentProject.stage.toLowerCase() == "new") ? (
                  <Button title="Delete" onClick={onClickDelete}>
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </FormElement>
          )}
        />
      )}
    </div>
  );
};

export default Edit;
