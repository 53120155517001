import http from "../http-common";

const getAll = (token) => {
  return http.get("/project", getConfig(token));
};

const get = (token, id) => {
  return http.get(`/project/${id}`, getConfig(token));
};

const create = (token, data) => {
  return http.post("/project", data, getConfig(token));
};

const update = (token, id, data) => {
  return http.put(`/project/${id}`, data, getConfig(token));
};

const remove = (token, id) => {
  return http.delete(`/project/${id}`, getConfig(token));
};

const getExport = (token) => {
  var config = getConfig(token);
  config.responseType = "blob";

  http.post("/file", data, config).then(function (response) {
    let fileName =
      response.headers["content-disposition"].split("filename=")[1];
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: "text/csv",
        }),
        fileName
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "text/csv",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        response.headers["content-disposition"].split("filename=")[1]
      );
      document.body.appendChild(link);
      link.click();
    }
  });
};

const getConfig = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return config;
};

const ProjectService = {
  getAll,
  get,
  create,
  update,
  remove,
  getExport,
};

export default ProjectService;
