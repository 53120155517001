import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { retrieveProjects } from "../../actions/projects";
import { useMsal } from "@azure/msal-react";
import { Outlet, Link } from "react-router-dom";

const Admin = () => {
  const dispatch = useDispatch();
  const msalContext = useMsal();

  useEffect(() => {
    dispatch(retrieveProjects(msalContext));
  }, []);

  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
