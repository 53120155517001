import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { process } from "@progress/kendo-data-query";
import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";
import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
  ToolbarSpacer,
} from "@progress/kendo-react-buttons";
import { retrieveProjects } from "../../actions/projects";
import { retrieveUserProfile } from "../../actions/userProfile";

function List() {
  const dispatch = useDispatch();
  const msalContext = useMsal();
  const projects = useSelector((state) => state.projects);
  const userProfile = useSelector((state) => state.userProfile);

  const [dataState, setDataState] = useState({
    filter: undefined,
    group: undefined,
    skip: undefined,
    sort: undefined,
    take: undefined,
  });

  const [filteredProjects, setfilteredProjects] = useState(projects);
  const [result, setResult] = useState({
    data: filteredProjects,
    total: filteredProjects.length,
  });

  const [search, setSearch] = useState("");

  const onSearchStateChange = (event) => {
    const projectsFiltered = projects.filter((p) => {
      const itemText =
        p.name +
        " " +
        p.jobNumber +
        " " +
        p.jobNumberPrecon +
        " " +
        p.jobNumberDesignBuild +
        " " +
        p.costCodeEstimating;
      return itemText.toLowerCase().includes(event.value.toLowerCase());
    });
    setfilteredProjects(projectsFiltered);
    setResult(process(projectsFiltered, dataState));
    setSearch(event.value);
  };

  const onSearchClear = (event) => {
    setfilteredProjects(projects);
    setResult(process(projects, dataState));
    setSearch("");
  };

  const onFilterClear = (event) => {
    const currentDataState = dataState;
    currentDataState.filter = undefined;
    setDataState(currentDataState);
    setResult(process(filteredProjects, dataState));
  };

  const onDataStateChange = (event) => {
    setDataState(event.dataState);
    setResult(process(filteredProjects, event.dataState));
  };

  const onExportClick = () => {};

  useEffect(() => {}, []);
  const navigate = useNavigate();

  const EditCommandCell = (props) => {
    return (
      <td>
        <Button
          icon="edit"
          onClick={() => {
            navigate(`edit/${props.dataItem.id}`);
          }}
        />
      </td>
    );
  };

  const ColumnMenuCheckboxFilter = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={filteredProjects}
          expanded={true}
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(retrieveUserProfile(msalContext));
    dispatch(retrieveProjects(msalContext));
  }, []);

  return (
    <div>
      <div>
        <Toolbar>
          <ToolbarItem>
            <Button
              icon="plus"
              title="Add Project"
              onClick={() => navigate("create")}
            >
              Add Project
            </Button>
          </ToolbarItem>
          {userProfile.isSuperAdmin ? (
            <ToolbarItem>
              <Button
                icon="upload"
                title="Import/Export"
                onClick={() => navigate("/upload")}
              >
                Import/Export
              </Button>
            </ToolbarItem>
          ) : (
            ""
          )}
          <ToolbarSeparator />
          <ToolbarItem>
            <strong>{result.total}</strong>&nbsp;projects
          </ToolbarItem>
          <ToolbarSpacer />
          <ToolbarItem>
            <Input
              value={search}
              onChange={onSearchStateChange}
              placeholder="Search for a project"
              style={{
                width: 300,
              }}
            ></Input>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="close"
              title="Clear search"
              onClick={onSearchClear}
            ></Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="filter-clear"
              title="Clear filter"
              onClick={onFilterClear}
            ></Button>
          </ToolbarItem>
        </Toolbar>

        <Grid
          data={result}
          sortable={true}
          scrollable={"none"}
          onDataStateChange={onDataStateChange}
          resizable={true}
          dataItemKey={"id"}
          {...dataState}
        >
          <GridColumn field="name" title="Project Name" />
          <GridColumn
            field="company"
            title="Company"
            width="80px"
            minResizableWidth={145}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            field="stage"
            title="Stage"
            minResizableWidth={160}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            field="jobNumber"
            title="Job No"
            minResizableWidth={160}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            field="jobNumberPrecon"
            title="Precon Job No"
            minResizableWidth={160}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            field="jobNumberDesignBuild"
            title="DB Job No"
            minResizableWidth={160}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            field="costCodeEstimating"
            title="Est Cost Code"
            minResizableWidth={160}
            columnMenu={ColumnMenuCheckboxFilter}
          />
          <GridColumn
            cell={EditCommandCell}
            sortable={false}
            filterable={false}
          />
        </Grid>
      </div>
    </div>
  );
}

export default List;
