// export const msalConfig = {
//     auth: {
//       clientId: "Enter_the_Application_Id_Here",
//       authority: "Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: "Enter_the_Redirect_Uri_Here",
//     },
//     cache: {
//       cacheLocation: "sessionStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     }
//   };

export const msalConfig = {
    auth: {
        clientId: "f478adb2-2d53-4e20-8f46-8cc67177ecdb",
        authority: "https://login.microsoftonline.com/10823c03-6460-48ea-9a0f-0276dcad6c5f"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
}
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
//   export const loginRequest = {
//    scopes: ["User.Read"]
//   };

// Coordinates and required scopes for your web API
export const apiConfig = {
    resourceUri: "https://localhost:44367/api/weatherforecast",
    resourceScopes: ["api://4c09160b-ae36-401d-b2f6-e8691f61676a/.default"]
}

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", ...apiConfig.resourceScopes]
}
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [...apiConfig.resourceScopes]
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile", ...apiConfig.resourceScopes]
}
