import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProjects } from "../actions/projects";

const JobListingTabLca = () => {
  const projectsSorted = useSelector((state) =>
    state.projects.sort(function (a, b) {
      return a.jobNumber.localeCompare(b.jobNumber);
    })
  );

  const projectsFiltered = projectsSorted.filter((p) => p.company === "LCA");

  const projectsUnderway = projectsFiltered.filter(
    (p) => p.stage.toLowerCase() === "underway"
  );

  const projectsEstimating = projectsFiltered.filter(
    (p) =>
      p.costCodeEstimating !== "" &&
      ["estimating", "precon"].includes(p.stage.toLowerCase())
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h4>Under Construction</h4>
          <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Job No</th>
                <th>Job Name</th>
              </tr>
            </thead>
            <tbody>
              {projectsUnderway &&
                projectsUnderway.map((project, index) => (
                  <tr key={project.id}>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.jobNumber}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="col">
          <h4>Estimating</h4>
          <table className="table table-striped" aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Cost Code</th>
                <th>Job Name</th>
              </tr>
            </thead>
            <tbody>
              {projectsEstimating &&
                projectsEstimating.map((project, index) => (
                  <tr key={project.id}>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.costCodeEstimating}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      }}
                    >
                      {project.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobListingTabLca;
