import React, { useState } from "react";
import { silentRequest } from "../authConfig";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";

export const FileUpload = () => {
  const msalContext = useMsal();
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;
  const request = {
    ...silentRequest,
    account: accounts[0],
  };

  const baseUri = window.location.origin;
  const filePostUri = baseUri + "/api/file";

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  const [status, setStatus] = useState("No file selected");

  const saveFile = (e) => {
    setStatus("Not posted");
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);

    const tokenResponse = await instance.acquireTokenSilent(request);
    try {
      let config = {
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
        },
      };

      setStatus("Posted, awaiting response");
      const res = await axios
        .post(filePostUri, formData, config)
        .then((response) => {
          console.log(response);
          setStatus("Success");
        });
    } catch (ex) {
      console.log(ex);
      setStatus("Error");
    }
  };

  const onClickExport = async () => {
    const tokenResponse = await instance.acquireTokenSilent(request);

    let config = {
      headers: {
        Authorization: "Bearer " + tokenResponse.accessToken,
      },
      responseType: "blob",
    };

    axios.get(filePostUri, config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "projects.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <input type="file" onChange={saveFile} />
      <input type="button" value="upload" onClick={uploadFile} />
      <p>Status: {status}</p>

      <Button icon="download" title="Export" onClick={onClickExport}>
        Export
      </Button>
    </>
  );
};
