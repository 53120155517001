import {
  CREATE_PROJECT,
  RETRIEVE_PROJECTS,
  RETRIEVE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DELETE_ALL_PROJECTS,
} from "./types";

import ProjectDataService from "../services/ProjectService";
import { silentRequest } from "../authConfig";
import { callMsGraph } from "../graph";

export const createProject =
  (msalContext, name, company) => async (dispatch) => {
    const accounts = msalContext.accounts;
    const instance = msalContext.instance;
    const request = {
      ...silentRequest,
      account: accounts[0],
    };

    const tokenResponse = await instance.acquireTokenSilent(request);
    try {
      const token = tokenResponse.accessToken;
      const projectResponse = await ProjectDataService.create(token, {
        name,
        company,
      });

      dispatch({
        type: CREATE_PROJECT,
        payload: projectResponse.data,
      });
      return Promise.resolve(projectResponse.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

export const retrieveProjects = (msalContext) => async (dispatch) => {
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;

  try {
    const request = {
      ...silentRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        ProjectDataService.getAll(response.accessToken).then((response) =>
          dispatch({
            type: RETRIEVE_PROJECTS,
            payload: response.data,
          })
        );
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          console.log("Access token (2): " + response.accessToken);
        });
      });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveProject = (msalContext, id) => async (dispatch) => {
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;
  const request = {
    ...silentRequest,
    account: accounts[0],
  };

  const tokenResponse = await instance.acquireTokenSilent(request);
  try {
    const token = tokenResponse.accessToken;
    const projectResponse = await ProjectDataService.get(token, id);

    dispatch({
      type: RETRIEVE_PROJECT,
      payload: projectResponse.data,
    });
    return Promise.resolve(projectResponse.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateProject = (msalContext, id, data) => async (dispatch) => {
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;
  const request = {
    ...silentRequest,
    account: accounts[0],
  };

  const tokenResponse = await instance.acquireTokenSilent(request);
  try {
    const token = tokenResponse.accessToken;
    const projectResponse = await ProjectDataService.update(token, id, data);

    dispatch({
      type: UPDATE_PROJECT,
      payload: projectResponse.data,
    });
    return Promise.resolve(projectResponse.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteProject = (msalContext, id) => async (dispatch) => {
  const accounts = msalContext.accounts;
  const instance = msalContext.instance;
  const request = {
    ...silentRequest,
    account: accounts[0],
  };

  const tokenResponse = await instance.acquireTokenSilent(request);
  try {
    const token = tokenResponse.accessToken;

    await ProjectDataService.remove(token, id);

    dispatch({
      type: DELETE_PROJECT,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};
