import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { retrieveUserProfile } from "../actions/userProfile";

const TopNav = () => {
  const userProfile = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const msalContext = useMsal();

  useEffect(() => {
    dispatch(retrieveUserProfile(msalContext));
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <a href="/" className="navbar-brand">
          Allegrone Projects
        </a>
        <div className="navbar-nav mr-auto">
          <li className="navbar-nav mr-auto">
            <Link to={"/joblisting"} className="nav-link">
              Job Listing
            </Link>
          </li>
          {userProfile.isAdmin ? (
            <li className="navbar-nav mr-auto">
              <Link to={"/admin"} className="nav-link">
                Project Admin
              </Link>
            </li>
          ) : (
            ""
          )}
        </div>
      </nav>
    </>
  );
};

export default TopNav;
