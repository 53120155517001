import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProjects } from "../actions/projects";
import { useMsal } from "@azure/msal-react";

import { Loader } from "@progress/kendo-react-indicators";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import JobListingTabAcc from "./JobListingTabAcc";
import JobListingTabLca from "./JobListingTabLca";
import JobListingTabBdc from "./JobListingTabBdc";

const Loading = () => {
  return (
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <p>Loading projects...</p>
      <Loader type={"converging-spinner"} />
    </div>
  );
};

const JobListing = () => {
  const [activeTab, setActiveTab] = useState("ACC");

  const projects = useSelector((state) => state.projects);

  const dispatch = useDispatch();
  const msalContext = useMsal();

  useEffect(() => {
    dispatch(retrieveProjects(msalContext));
  }, []);

  return (
    <div class="container">
      <Tabs id="tabs" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
        <Tab eventKey="ACC" title="ACC">
          <p></p>
          {projects.length > 0 ? (
            <JobListingTabAcc></JobListingTabAcc>
          ) : (
            <Loading />
          )}
        </Tab>
        <Tab eventKey="LCA" title="LCA">
          <p></p>
          {projects.length > 0 ? (
            <JobListingTabLca></JobListingTabLca>
          ) : (
            <Loading />
          )}
        </Tab>
        <Tab eventKey="BCD" title="BCD">
          <p></p>
          {projects.length > 0 ? (
            <JobListingTabBdc></JobListingTabBdc>
          ) : (
            <Loading />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default JobListing;
