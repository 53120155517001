import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProjects } from "../actions/projects";

const JobListingTabAcc = () => {
  const projectsSorted = useSelector((state) =>
    state.projects.sort(function (a, b) {
      return a.jobNumber.localeCompare(b.jobNumber);
    })
  );

  const projectsFiltered = projectsSorted.filter((p) => p.company === "ACC");

  const projectsUnderway = projectsFiltered.filter(
    (p) => p.stage.toLowerCase() === "underway"
  );

  const projectsPrecon = projectsFiltered.filter(
    (p) => p.jobNumberPrecon !== ""
  );

  const projectsEstimating = projectsFiltered.filter(
    (p) =>
      p.costCodeEstimating !== "" &&
      ["estimating", "precon"].includes(p.stage.toLowerCase())
  );

  return (
    <div className="row">
      <div className="col">
        <h4>Under Construction</h4>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Job No</th>
              <th>Job Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projectsUnderway &&
              projectsUnderway.map((project, index) => (
                <tr key={project.id}>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.jobNumber}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.name}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.sharepoint.documentsCollectionUri !== "" ? (
                      <a
                        href={project.sharepoint.documentsCollectionUri}
                        target="_blank"
                      >
                        Files
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.procore.projectUri !== "" ? (
                      <a href={project.procore.projectUri} target="_blank">
                        Procore
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="col">
        <h4>Preconstruction</h4>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Job No</th>
              <th>Job Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projectsPrecon &&
              projectsPrecon.map((project, index) => (
                <tr key={project.id}>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.jobNumberPrecon}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.name}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.sharepoint.documentsCollectionUri !== "" ? (
                      <a
                        href={project.sharepoint.documentsCollectionUri}
                        target="_blank"
                      >
                        Files
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.procore.projectUri !== "" ? (
                      <a href={project.procore.projectUri} target="_blank">
                        Procore
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <p></p>
        <h4>Estimating</h4>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Cost Code</th>
              <th>Job Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projectsEstimating &&
              projectsEstimating.map((project, index) => (
                <tr key={project.id}>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.costCodeEstimating}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.name}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.sharepoint.documentsCollectionUri !== "" ? (
                      <a
                        href={project.sharepoint.documentsCollectionUri}
                        target="_blank"
                      >
                        Files
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    }}
                  >
                    {project.procore.projectUri !== "" ? (
                      <a href={project.procore.projectUri} target="_blank">
                        Procore
                      </a>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobListingTabAcc;
