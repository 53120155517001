import React from "react";
import { Outlet } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import TopNav from "./components/topNav";

import "./styles/App.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";

const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <TopNav />
        <div className="container-fluid mt-3">
          <Outlet />
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <h1>Allegrone Projects</h1>
          <p>Signing you in</p>
          <Loader type={"converging-spinner"} />
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  // Authenticate automatically
  useMsalAuthentication(InteractionType.Redirect);

  return <MainContent />;
}
